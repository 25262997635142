import { reactive, ref, onBeforeUnmount } from "vue";
import router from "@/router/index.js";
import HeaderNav from "@/components/HeaderNav";
import Banner from "@/components/Banner.vue";
import Header from "@/components/Header.vue";
import banner1 from "@/assets/home/banner1.png";
import banner2 from "@/assets/home/banner2.png";
import banner3 from "@/assets/home/banner3.png";
import banner4 from "@/assets/home/banner4.png";

// 新闻资讯和硬核案例接口
import { getDynamicList, getProjectList } from "@/apiAndRequest/index.js";
export default {
  components: {
    HeaderNav,
    Banner,
    Header
  },
  setup() {
    // 轮播图数据
    let bannerList = reactive([{
      src: banner1,
      id: 1
    }, {
      src: banner2,
      id: 2
    }, {
      src: banner3,
      id: 3
    }, {
      src: banner4,
      id: 4
    }]);
    // 导航栏随着滚动条移动变色
    let textColor = ref("#fff");
    let activeColor = ref("#fff");
    let logoShow = ref(true);
    window.addEventListener("scroll", handlerScroll);
    function handlerScroll() {
      let scrollTop = Number.parseInt(document.documentElement.scrollTop) || Number.parseInt(document.body.scrollTop);
      let topHeight = document.getElementById("banner").offsetHeight - 50;
      if (scrollTop >= topHeight) {
        textColor.value = "#333333";
        activeColor.value = "#269272";
        logoShow.value = false;
      } else {
        textColor.value = "#fff";
        activeColor.value = "#fff";
        logoShow.value = true;
      }
    }
    onBeforeUnmount(_ => {
      window.removeEventListener("scroll", handlerScroll);
    });

    // 切换新闻资讯和硬核案列显示
    let activeIndex = ref("0");
    function handleSelect(key) {
      activeIndex.value = key;
    }
    // 获取新闻资讯
    let newsList = reactive({
      title: "新闻资讯",
      list: []
    });
    let activeNews = ref(0);
    getDynamicList({
      page: 1,
      limit: 8
    }).then(res => {
      let result = res.data[0].list;
      newsList.list = result;
    });
    function handerMouseover(index) {
      activeNews.value = index;
    }
    function open(src) {
      window.open(src);
    }
    //硬核案列
    let caseShow = reactive({
      title: "硬核案例",
      list: []
    });
    let activeCase = ref(0);
    getProjectList({
      page: 1,
      limit: 8
    }).then(res => {
      caseShow.list = res.data[0].list;
    });
    function caseMouseOver(index) {
      activeCase.value = index;
    }
    function changeIndex() {
      activeNews.value = 0;
      activeCase.value = 0;
    }
    document.documentElement.addEventListener("mouseover", changeIndex);
    onBeforeUnmount(_ => {
      document.documentElement.removeEventListener("mouseover", changeIndex);
    });

    // 合作伙伴
    let partnerAll = [require("@/assets/home/partner/1.png"), require("@/assets/home/partner/2.png"), require("@/assets/home/partner/3.png"), require("@/assets/home/partner/4.png"), require("@/assets/home/partner/5.png"), require("@/assets/home/partner/6.png"), require("@/assets/home/partner/7.png"), require("@/assets/home/partner/8.png"), require("@/assets/home/partner/9.png"), require("@/assets/home/partner/10.png"), require("@/assets/home/partner/11.png"), require("@/assets/home/partner/12.png"), require("@/assets/home/partner/13.png"), require("@/assets/home/partner/14.png"), require("@/assets/home/partner/15.png"), require("@/assets/home/partner/16.png"), require("@/assets/home/partner/17.png"), require("@/assets/home/partner/18.png"), require("@/assets/home/partner/19.png"), require("@/assets/home/partner/20.png"), require("@/assets/home/partner/21.png"), require("@/assets/home/partner/22.png"), require("@/assets/home/partner/23.png"), require("@/assets/home/partner/24.png"), require("@/assets/home/partner/25.png"), require("@/assets/home/partner/26.png"), require("@/assets/home/partner/27.png"), require("@/assets/home/partner/28.png"), require("@/assets/home/partner/29.png"), require("@/assets/home/partner/30.png"), require("@/assets/home/partner/31.png"), require("@/assets/home/partner/32.png"), require("@/assets/home/partner/33.png"), require("@/assets/home/partner/34.png")];
    let total = partnerAll.length;
    let size = 17;
    let partnerShow = reactive({
      currentPage: 0,
      list: partnerAll.slice(0, size)
    });
    function goToNextPage() {
      partnerShow.currentPage++;
      if (partnerShow.currentPage * size >= total) {
        partnerShow.currentPage = 0;
      }
      partnerShow.list = partnerAll.slice(partnerShow.currentPage * size, (partnerShow.currentPage + 1) * size);
    }
    return {
      // 轮播图数据
      bannerList,
      textColor,
      activeColor,
      logoShow,
      // 企业新闻数据
      activeIndex,
      handleSelect,
      newsList,
      activeNews,
      handerMouseover,
      // 硬核案例
      caseShow,
      caseMouseOver,
      activeCase,
      open,
      // 路由
      router,
      // 合作伙伴
      partnerShow,
      goToNextPage
    };
  }
};