import { Swiper, SwiperSlide } from "swiper/vue";
//自动播放，混动
import { Autoplay, Navigation, Pagination } from "swiper";
//样式
import "swiper/css";
import "swiper/css/autoplay";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
export default {
  name: "Banner",
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    bannerList: Array
  },
  setup() {
    return {
      modules: [Autoplay, Navigation, Pagination]
    };
  }
};