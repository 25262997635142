import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "header-bg"
};
const _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "header",
    style: _normalizeStyle({
      padding: $props.headerPadding
    })
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("p", null, _toDisplayString($props.textBg), 1)]), _createElementVNode("h2", {
    style: _normalizeStyle({
      color: $props.textColor,
      textAlign: $props.textPosition,
      fontSize: $props.titleFontSize + 'px'
    })
  }, _toDisplayString($props.title), 5), _createElementVNode("div", {
    class: "beautifulLineBox",
    style: _normalizeStyle({
      justifyContent: $props.linePosition
    })
  }, [_createElementVNode("div", {
    class: "beautifulLine",
    style: _normalizeStyle({
      background: $props.lineColor,
      height: $props.lineHeight + 'px',
      width: $props.lineWidth + 'px'
    })
  }, null, 4)], 4), _createElementVNode("div", {
    class: "text-gary",
    innerHTML: $props.content
  }, null, 8, _hoisted_2)], 4);
}