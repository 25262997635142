import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import lazyPlugin from 'vue3-lazy';
import loadingImg from "@/assets/home/loading.png";

import "@/styles/base.css";
import "@/styles/index.css"

createApp(App).use(store).use(router).use(lazyPlugin,{
  loading: loadingImg,
  error: loadingImg,
}).mount('#app')
