import "core-js/modules/es.array.push.js";
import qrCode from "@/assets/home/qrcode.png";
import router from "@/router/index";
export default {
  setup() {
    let footerData = [{
      title: "联系方式",
      id: "contact1",
      rowsContent: [{
        content: "邮编：430000",
        router: ""
      }, {
        content: "联系电话：027-81778605",
        router: ""
      }, {
        content: "售后电话：400-6767-723",
        router: ""
      }, {
        content: "empty",
        router: ""
      }, {
        content: "武汉市东湖高新区南山光谷自贸港D1栋",
        router: ""
      }]
    }, {
      title: "关于奇力士",
      id: "aboutCompany2",
      rowsContent: [{
        content: "奇力士介绍",
        router: "/aboutUs"
      }, {
        content: "奇力士资质荣誉",
        router: "/usHonors"
      }, {
        content: "全过程二次供水系统服务商",
        router: "/serviceProvider"
      }]
    }, {
      title: "奇力士资讯",
      id: "information3",
      rowsContent: [{
        content: "工程案例",
        router: "/projectCase"
      }, {
        content: "企业动态",
        router: "/dynamicState"
      }]
    }, {
      title: "智联设备",
      id: "equipment4",
      rowsContent: [{
        content: "单泵系列",
        router: "/pumpSeries/1"
      }, {
        content: "新一代超静音二次供水设备",
        router: "/pumpSeries/2"
      }, {
        content: "防淹二次供水设备",
        router: "/pumpSeries/3"
      }, {
        content: "永磁高效二次供水设备",
        router: "/pumpSeries/4"
      }, {
        content: "通用二次供水设备",
        router: "/pumpSeries/5"
      }, {
        content: "供水应用场景系列",
        router: "/pumpSeries/6"
      }]
    }, {
      title: "暖男卫士",
      id: "warmBoy5",
      rowsContent: [{
        content: "深化设计",
        router: "/deepDesign"
      }, {
        content: "二次供水综合管理平台",
        router: "/managementPlatform"
      }, {
        content: "工程交付",
        router: "/dateReleased"
      }, {
        content: "暖男服务",
        router: "/warmBoy"
      }]
    }, {
      title: "联系我们",
      id: "contactUs6",
      rowsContent: [{
        content: "联系方式",
        router: "/contactWay"
      }, {
        content: "人才招聘",
        router: ""
      }, {
        content: "平台登录",
        router: "/loginPlatform"
      }]
    }, {
      title: "奇力士微信公众号",
      id: "weChat7",
      qrCode
    }];
    function clickJumpTo(item) {
      if (item.router) {
        // 跳转到对应的页面
        router.push(item.router);
      }
    }
    function goToGongXin() {
      window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502004526');
    }
    return {
      footerData,
      clickJumpTo,
      goToGongXin
    };
  }
};