import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  id: "banner",
  class: "swiper"
};
const _hoisted_2 = {
  style: {
    "width": "100%"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_swiper_slide = _resolveComponent("swiper-slide");
  const _component_swiper = _resolveComponent("swiper");
  const _directive_lazy = _resolveDirective("lazy");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_swiper, {
    "slides-per-view": 1,
    "space-between": 0,
    modules: $setup.modules,
    navigation: "",
    autoplay: {
      delay: 2000,
      stopOnLastSlide: false,
      disableOnInteraction: false
    },
    pagination: {
      clickable: true
    },
    loop: true
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.bannerList, (item, index) => {
      return _openBlock(), _createBlock(_component_swiper_slide, {
        key: index,
        class: "text-center"
      }, {
        default: _withCtx(() => [_withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [[_directive_lazy, item.src]])]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }, 8, ["modules"])]);
}