export default {
  name: "Header",
  props: {
    title: String,
    content: String,
    textBg: String,
    // 标题颜色
    textColor: {
      type: String,
      default: "#333"
    },
    lineColor: {
      type: String,
      default: "#269272"
    },
    textPosition: {
      type: String,
      default: "center"
    },
    linePosition: {
      type: String,
      default: "center"
    },
    titleFontSize: {
      type: Number,
      default: 24
    },
    lineHeight: {
      type: Number,
      default: 4
    },
    lineWidth: {
      type: Number,
      default: 48
    },
    headerPadding: {
      type: String,
      default: "30px 0 20px 0"
    }
  }
};