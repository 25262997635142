import "core-js/modules/es.array.push.js";
import router from "@/router/index";
import logo from "@/assets/Logo/qlslogo.png";
import logoWhite from "@/assets/Logo/logo-white.png";
export default {
  name: "Tags",
  props: {
    textColor: {
      type: String,
      default: "#333333"
    },
    bgColor: {
      type: String,
      default: "transparent"
    },
    activeColor: {
      type: String,
      default: "#269272"
    },
    isChangeBg: String,
    logoShow: {
      type: Boolean,
      default: false
    },
    isShadow: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    function jumpToHome() {
      router.push("/");
    }
    function goToView(path) {
      router.push(path);
    }
    return {
      jumpToHome,
      goToView
    };
  }
};