import { createStore } from 'vuex'

export default createStore({
  state: {
    downloadPdfCount:0,
  },
  getters: {
  },
  mutations: {
    addDownloadPdfCount(state){
      state.downloadPdfCount++;
    }
  },
  actions: {
  },
  modules: {
  }
})
