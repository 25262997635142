import { watch } from "vue";
import { useRoute } from "vue-router";
import Footer from "@/components/Footer";
export default {
  component: {
    Footer
  },
  setup() {
    // let route = useRoute();
    // watch(route, (newValue) => {
    //   if (newValue) {
    //     if (!newValue.path.includes("/pumpSeries")) {
    //       document.body.scrollTop=0;
    //       document.documentElement.scrollTop = 0;
    //     }
    //   }
    // });
  }
};