import axios from "axios";
axios.defaults.baseURL = "https://api-gw.qls70.com";
// axios.defaults.baseURL = "http://192.168.20.66:8084";
axios.defaults.timeout=10000;

// 新闻资讯，企业动态
export function getDynamicList(params) {
  return axios({
    url: '/company/getCompanyByPage',
    method: 'get',
    params
  })
}


// 硬核案例，工程案例
export function getProjectList(params) {
  return axios({
    url: '/project/getProjectByPage',
    method: 'get',
    params,
  })
}

// 提交留言
export const submitInfo = params => {
  return axios({
    method: "post",
    url: "/contact/add",
    params: params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })
}
// 招聘
export function getJobList(params) {
  return axios({
    url: '/recruited/selestByPost',
    method: 'get',
    params
  })
}

// 获取招聘类型
export const getJobTypeList = params => {
  return axios.get('/recruited/getPost', params)
}


// 提交简历
export function postResume(data){
  return axios({
    method:"post",
    url: "/resume/add",
    data,
  })
}