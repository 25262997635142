import {
  createRouter,
  createWebHistory
} from 'vue-router';
import HomeView from "@/views/HomeView.vue";

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: "/aboutUsTitle",
    component: () => import("../views/aboutUs/index.vue"),
    redirect: {
      name: 'aboutUs'
    },
    children: [{
        path: "/aboutUs",
        name: "aboutUs",
        component: () => import("../views/aboutUs/AboutUs.vue"),
      },
      {
        path: "/usHonors",
        name: "usHonors",
        component: () => import("../views/aboutUs/UsHonors.vue"),
      },
      {
        path: "/serviceProvider",
        name: "serviceProvider",
        component: () => import("../views/aboutUs/ServiceProvider.vue"),
      },
    ]
  },
  {
    path: "/usInformationTitle",
    component: () => import("../views/usInformation/index.vue"),
    redirect: {
      name: 'projectCase'
    },
    children: [{
        path: "/projectCase",
        name: "projectCase",
        component: () => import("../views/usInformation/ProjectCase.vue"),
      },
      {
        path: "/dynamicState",
        name: "dynamicStates",
        component: () => import("../views/usInformation/DynamicState.vue"),
      },
    ]
  },
  {
    path: "/smartEquipmentTitle",
    component: () => import("../views/smartEquipment/index.vue"),
    redirect: {
      name: 'pumoSeries'
    },
    children: [{
        path: "/pumpSeries/:id",
        name: "pumoSeries",
        component: () => import("../views/smartEquipment/PumpSeries.vue"),
      },
      {
        path: "/aboutPump/:id",
        name: "aboutPump",
        component: () => import("../views/smartEquipment/AboutPump.vue"),
      },
    ]
  },
  {
    path:"/lifePump",
    component:()=>import("../views/lifePump/LifePump.vue")
  },
  {
    path: "/warmBoyTitle",
    component: () => import("../views/warmBoy/index.vue"),
    redirect: {
      name: 'deepDesign'
    },
    children: [{
        path: "/deepDesign",
        name: "deepDesign",
        component: () => import("../views/warmBoy/DeepDesign.vue"),
      },
      {
        path: "/managementPlatform",
        name: "managementPlatform",
        component: () => import("../views/warmBoy/ManagementPlatform.vue"),
      },
      {
        path: "/dateReleased",
        name: "dateReleased",
        component: () => import("../views/warmBoy/DateReleased.vue"),
      },
      {
        path: "/warmBoy",
        name: "warmBoy",
        component: () => import("../views/warmBoy/WarmBoy.vue"),
      },
    ]
  },
  {
    path: "/contactUs",
    component: () => import("../views/contactUs/index.vue"),
    redirect: {
      name: 'contactWay'
    },
    children: [
      {
        path: "/findPartner",
        name: "findPartner",
        component: () => import("../views/contactUs/FindPartner.vue"),
      },
      {
        path: "/contactWay",
        name: "contactWay",
        component: () => import("../views/contactUs/ContactWay.vue"),
      },
      {
        path: "/loginPlatform",
        name: "loginPlatform",
        component: () => import("../views/contactUs/LoginPlatform.vue"),
      },
      {
        path: "/resume",
        name: "resumePlatform",
        component: () => import("../views/contactUs/ResumePlatform.vue"),
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to,from)=>{
  if (!to.path.includes("/pumpSeries")){
    let toR = to.path.match(/^\/([A-Za-z])+/)||[];
    let fromR = from.path.match(/^\/([A-Za-z])+/)||[];
    if (toR[0] !== fromR[0]){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }
})
export default router