import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/Logo/logo-white.png';
import _imports_1 from '@/assets/Logo/qlslogo.png';
const _withScopeId = n => (_pushScopeId("data-v-517ca19e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "nav-content-section container"
};
const _hoisted_2 = {
  src: _imports_0,
  height: "40",
  style: {
    "margin": "10px"
  }
};
const _hoisted_3 = {
  src: _imports_1,
  height: "40",
  style: {
    "margin": "10px"
  }
};
const _hoisted_4 = {
  class: "nav-tags"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_menu_item = _resolveComponent("el-menu-item");
  const _component_el_sub_menu = _resolveComponent("el-sub-menu");
  const _component_el_menu = _resolveComponent("el-menu");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["nav-background-section", {
      hasShadow: $props.isShadow
    }]),
    style: _normalizeStyle({
      'background-color': $props.isChangeBg
    })
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("h1", {
    class: "nav-logo",
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.jumpToHome && $setup.jumpToHome(...args))
  }, [_withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [[_vShow, $props.logoShow]]), _withDirectives(_createElementVNode("img", _hoisted_3, null, 512), [[_vShow, !$props.logoShow]])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_menu, {
    "default-active": _ctx.$route.path,
    mode: "horizontal",
    "text-color": $props.textColor,
    "background-color": $props.bgColor,
    "active-text-color": $props.activeColor,
    "collapse-transition": false,
    ellipsis: false,
    "unique-opened": "",
    router: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_el_sub_menu, {
      index: "1",
      "show-timeout": 100,
      "popper-class": "changPopupBg",
      "popper-offset": 0,
      onClick: _cache[1] || (_cache[1] = _withModifiers($event => $setup.goToView('/aboutUs'), ["stop"]))
    }, {
      title: _withCtx(() => [_createTextVNode("关于奇力士")]),
      default: _withCtx(() => [_createVNode(_component_el_menu_item, {
        index: "/aboutUs"
      }, {
        default: _withCtx(() => [_createTextVNode("奇力士介绍")]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/usHonors"
      }, {
        default: _withCtx(() => [_createTextVNode("奇力士资质及荣誉")]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/serviceProvider"
      }, {
        default: _withCtx(() => [_createTextVNode("全过程二次供水系统服务商")]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_sub_menu, {
      index: "2",
      "show-timeout": 100,
      "popper-class": "changPopupBg",
      "popper-offset": 0,
      onClick: _cache[2] || (_cache[2] = _withModifiers($event => $setup.goToView('/projectCase'), ["stop"]))
    }, {
      title: _withCtx(() => [_createTextVNode("奇力士资讯")]),
      default: _withCtx(() => [_createVNode(_component_el_menu_item, {
        index: "/projectCase"
      }, {
        default: _withCtx(() => [_createTextVNode("工程案例")]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/dynamicState"
      }, {
        default: _withCtx(() => [_createTextVNode("企业动态")]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_sub_menu, {
      index: "3",
      "show-timeout": 100,
      "popper-class": "changPopupBg",
      "popper-offset": 0,
      onClick: _cache[3] || (_cache[3] = _withModifiers($event => $setup.goToView('/pumpSeries/1'), ["stop"]))
    }, {
      title: _withCtx(() => [_createTextVNode(" 智联设备 ")]),
      default: _withCtx(() => [_createVNode(_component_el_menu_item, {
        index: "/pumpSeries/1"
      }, {
        default: _withCtx(() => [_createTextVNode("单泵系列")]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/pumpSeries/2"
      }, {
        default: _withCtx(() => [_createTextVNode("新一代超静音二次供水设备")]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/pumpSeries/3"
      }, {
        default: _withCtx(() => [_createTextVNode("防淹二次供水设备")]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/pumpSeries/4"
      }, {
        default: _withCtx(() => [_createTextVNode("永磁高效二次供水设备")]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/pumpSeries/5"
      }, {
        default: _withCtx(() => [_createTextVNode("通用二次供水设备")]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/pumpSeries/6"
      }, {
        default: _withCtx(() => [_createTextVNode("供水应用场景系列")]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_menu_item, {
      index: "/lifePump",
      class: "lifePump"
    }, {
      default: _withCtx(() => [_createTextVNode(" 生命泵房 ")]),
      _: 1
    }), _createVNode(_component_el_sub_menu, {
      index: "4",
      "show-timeout": 100,
      "popper-class": "changPopupBg",
      "popper-offset": 0,
      onClick: _cache[4] || (_cache[4] = _withModifiers($event => $setup.goToView('/deepDesign'), ["stop"]))
    }, {
      title: _withCtx(() => [_createTextVNode("暖男卫士")]),
      default: _withCtx(() => [_createVNode(_component_el_menu_item, {
        index: "/deepDesign"
      }, {
        default: _withCtx(() => [_createTextVNode(" 深化设计 ")]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/managementPlatform"
      }, {
        default: _withCtx(() => [_createTextVNode(" 物联平台 ")]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/dateReleased"
      }, {
        default: _withCtx(() => [_createTextVNode(" 工程交付 ")]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/warmBoy"
      }, {
        default: _withCtx(() => [_createTextVNode(" 智慧运维 ")]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_sub_menu, {
      index: "5",
      "show-timeout": 100,
      "popper-class": "changPopupBg",
      "popper-offset": 0,
      onClick: _cache[5] || (_cache[5] = _withModifiers($event => $setup.goToView('/findPartner'), ["stop"]))
    }, {
      title: _withCtx(() => [_createTextVNode("联系我们")]),
      default: _withCtx(() => [_createVNode(_component_el_menu_item, {
        index: "/findPartner"
      }, {
        default: _withCtx(() => [_createTextVNode(" 招募合伙人 ")]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/contactWay"
      }, {
        default: _withCtx(() => [_createTextVNode(" 联系方式 ")]),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "/loginPlatform"
      }, {
        default: _withCtx(() => [_createTextVNode(" 平台登录 ")]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["default-active", "text-color", "background-color", "active-text-color"])])])], 6);
}